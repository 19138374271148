import InfoIcon from '@mui/icons-material/Info';
import { IconButton, MenuItem, Select, Skeleton, Stack } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { homeAcademyAnalysisDropDownValuesState } from './pageAtoms';
import { Attribute } from 'components/shared/AcademyAnalysis/types';
import NoOptionMenu from 'components/shared/NoOptionMenu';

interface Props {
  isFetching: boolean;
  attributes: Attribute[];
}

const customStyles = {
  entrySelect: {
    minWidth: 250,
    maxWidth: 300,
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
    },
  },
  leaderboardIconStack: {
    alignItems: 'center',
  },
  leaderboardIcon: {
    fontSize: 32,
    color: 'black',
  },
  skeleton: {
    mr: 3,
    borderRadius: 2,
  },
};

const AttributeSelectField = ({ isFetching, attributes }: Props) => {
  const isInitial = useRef(true);

  const [dropDownValues, setDropDownValues] = useRecoilState(
    homeAcademyAnalysisDropDownValuesState
  );

  useEffect(() => {
    if (isInitial.current && attributes.length === 0) {
      setDropDownValues((previous) => ({
        ...previous,
        measurementId: null,
      }));

      isInitial.current = false;

      return;
    }

    const defaultAttributeId = attributes[0]?.id;
    if (defaultAttributeId) {
      setDropDownValues((previous) => ({
        ...previous,
        measurementId: defaultAttributeId,
      }));

      isInitial.current = true;
    }
  }, [attributes, setDropDownValues]);

  return (
    <>
      {isFetching ? (
        <Skeleton
          variant="rectangular"
          width={250}
          height={35}
          sx={customStyles.skeleton}
        />
      ) : (
        <Stack direction="row" sx={customStyles.leaderboardIconStack}>
          <IconButton>
            <InfoIcon sx={customStyles.leaderboardIcon} />
          </IconButton>

          <Select
            size="small"
            placeholder="Nothing selected"
            value={dropDownValues.measurementId}
            onChange={async (event) => {
              setDropDownValues((previous) => ({
                ...previous,
                measurementId: Number(event.target.value),
              }));
            }}
            sx={customStyles.entrySelect}
          >
            {attributes ? (
              attributes.map((attribute, index) => (
                <MenuItem key={index} value={attribute.id}>
                  {attribute.name}
                </MenuItem>
              ))
            ) : (
              <NoOptionMenu />
            )}
          </Select>
        </Stack>
      )}
    </>
  );
};

export default AttributeSelectField;
