import { Box, Skeleton, TableCell, TableHead, TableRow } from '@mui/material';

const leftIndex = 2;

const customStyles = {
  skeleton: {
    height: 35,
    width: 50,
    background: 'typography.secondary',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
  },
  nameBox: {
    display: 'flex',
    justifyContent: 'left',
  },
};

const LeaderboardHeaderSkeleton = () => (
  <TableHead>
    <TableRow>
      {Array.from({ length: 6 }).map((_, index) => (
        <TableCell key={index} align={index === leftIndex ? 'left' : 'center'}>
          <Box
            sx={index === leftIndex ? customStyles.nameBox : customStyles.box}
          >
            <Skeleton sx={customStyles.skeleton} />
          </Box>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default LeaderboardHeaderSkeleton;
