import { Grid, Skeleton } from '@mui/material';

import { gridStyles } from '../pageHelpers';

const customStyles = {
  counterValue: {
    fontSize: 64,
    borderRadius: 4,
  },
  counterDescription: {
    fontSize: 14,
    width: '10vw',
  },
  mainGrid: {
    justifyContent: 'space-between',
    pr: 25,
  },
};

const CounterSkeleton = () => (
  <Grid container spacing={4} sx={customStyles.mainGrid}>
    {Array.from({ length: 4 }).map((_, index) => (
      <Grid
        key={index}
        item
        xs={12}
        sm={6}
        md={3}
        lg={2}
        sx={index === 0 ? {} : gridStyles}
      >
        <Skeleton sx={customStyles.counterValue} variant="text" />

        <Skeleton sx={customStyles.counterDescription} variant="text" />
      </Grid>
    ))}
  </Grid>
);

export default CounterSkeleton;
