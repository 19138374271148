import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';

import { gridStyles } from '../pageHelpers';
import Counter from './Counter';
import CounterSkeleton from './CounterSkeleton';
import PageContainer from 'components/shared/PageContainer';
import { trpc } from 'helpers/trpc';

const customStyles = {
  counterText: {
    mt: -4,
  },
  mainGrid: {
    justifyContent: 'space-between',
    width: '100%',
  },
};

const CounterSection = () => {
  const {
    data: dashboardStats,
    isFetching: isDashboardStatsFetching,
    refetch: refetchDashboardStats,
  } = trpc.home.getDashboardStats.useQuery(undefined, { enabled: false });

  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  useEffect(() => {
    refetchDashboardStats();
  }, [refetchDashboardStats, currentUser?.club.activeClubId]);

  const dashboardStatsSection = (
    <Grid container sx={customStyles.mainGrid}>
      <Grid item xs={12} sm={6} md={3}>
        <Counter endValue={dashboardStats?.totalDrillPlayersCount || 0} />

        <Typography sx={customStyles.counterText}>
          Total players entered trials
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3} sx={gridStyles}>
        <Counter endValue={dashboardStats?.totalDrillEntriesCount || 0} />

        <Typography sx={customStyles.counterText}>
          Total club drill entries
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3} sx={gridStyles}>
        <Counter endValue={dashboardStats?.totalDrillPlayers30DaysCount || 0} />

        <Typography sx={customStyles.counterText}>
          New players in last 30 days
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3} sx={gridStyles}>
        <Counter endValue={dashboardStats?.totalDrillEntries30DaysCount || 0} />

        <Typography sx={customStyles.counterText}>
          New drill entries in last 30 days
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <PageContainer pageTitle="Dashboard">
      {isDashboardStatsFetching ? <CounterSkeleton /> : dashboardStatsSection}
    </PageContainer>
  );
};

export default CounterSection;
