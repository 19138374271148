import {
  Box,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { playersPerPage } from '../pageHelpers';

const customStyles = {
  boxSkeleton: {
    height: 30,
    width: '2vw',
  },
  avatarSkeleton: {
    width: 48,
    height: 48,
  },
  nameSkelton: {
    width: '10vw',
    height: 30,
    maxWidth: 'calc(100% - 10px)',
    mb: -3,
  },
  teamSkelton: {
    width: '6vw',
    height: 25,
    maxWidth: 'calc(100% - 10px)',
  },
  tableCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  nameStack: {
    ml: -1,
  },
  positionSkeleton: {
    ml: -2,
    width: '8vw',
    height: 30,
    maxWidth: 'calc(100% - 10px)',
  },
};

const LeaderboardSkeleton = () => (
  <TableBody>
    {Array.from({ length: playersPerPage }).map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Box sx={customStyles.tableCell}>
            <Skeleton sx={customStyles.boxSkeleton} variant="text" />
          </Box>
        </TableCell>

        <TableCell align="center">
          <Box sx={customStyles.tableCell}>
            <Skeleton sx={customStyles.avatarSkeleton} variant="circular" />
          </Box>
        </TableCell>

        <TableCell align="left">
          <Stack spacing={2} sx={customStyles.nameStack}>
            <Skeleton sx={customStyles.nameSkelton} variant="text" />

            <Skeleton sx={customStyles.teamSkelton} variant="text" />
          </Stack>
        </TableCell>

        <TableCell align="left">
          <Skeleton sx={customStyles.positionSkeleton} variant="text" />
        </TableCell>

        <TableCell key={index}>
          <Box sx={customStyles.tableCell}>
            <Skeleton sx={customStyles.boxSkeleton} variant="text" />
          </Box>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

export default LeaderboardSkeleton;
