import { Stack } from '@mui/material';

import AcademyAnalysisSection from './AcademyAnalysisSection';
import CommercialSection from './CommercialSection';
import CounterSection from './CounterSection';
import RecruitmentSection from './RecruitmentCenterSection';
import PageContainer from 'components/shared/PageContainer';
import { trpc } from 'helpers/trpc';

const sectionPermissions = {
  recruitmentCentreAccess: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '4:LocalOrRegionalScout',
    '9:AiSCOUTStaff',
    '10:Scout',
    '15:RecruitmentCentre',
  ],
  scoutHubAccess: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '4:LocalOrRegionalScout',
    '9:AiSCOUTStaff',
    '10:Scout',
  ],
  academyAnalysisAccess: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
    '9:AiSCOUTStaff',
  ],
  commercialAreaAccess: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '5:LeadOrHeadCommercial',
    '6:CommercialStaff',
    '9:AiSCOUTStaff',
  ],
  statsAccess: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '4:LocalOrRegionalScout',
    '9:AiSCOUTStaff',
  ],
};

const HomeContent = () => {
  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  const role = currentUser?.user.aiSCOUTRole!;

  const customStyles = {
    headingSkeleton: {
      height: 40,
      width: 200,
    },
    stack: {
      width: '100%',
      mt: sectionPermissions.statsAccess.includes(role) ? 0 : 17.5,
    },
    titleSkeleton: {
      width: 250,
      height: 40,
    },
    pageContainer: {
      mt: 0.5,
    },
    emptyContainer: {
      mt: 0,
    },
  };

  const isOtherRole = [
    sectionPermissions.statsAccess,
    sectionPermissions.commercialAreaAccess,
    sectionPermissions.academyAnalysisAccess,
    sectionPermissions.recruitmentCentreAccess,
  ].every((accessList) => !accessList.includes(role));

  return (
    <Stack sx={customStyles.stack}>
      {sectionPermissions.statsAccess.includes(role) && <CounterSection />}

      {sectionPermissions.recruitmentCentreAccess.includes(role) && (
        <RecruitmentSection />
      )}

      {sectionPermissions.academyAnalysisAccess.includes(role) && (
        <AcademyAnalysisSection />
      )}

      {sectionPermissions.commercialAreaAccess.includes(role) && (
        <CommercialSection />
      )}

      {isOtherRole && <PageContainer sx={customStyles.emptyContainer} />}
    </Stack>
  );
};

export default HomeContent;
