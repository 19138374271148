import InfoIcon from '@mui/icons-material/Info';
import { IconButton, MenuItem, Select, Skeleton, Stack } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { homeAcademyAnalysisDropDownValuesState } from './pageAtoms';
import { Measurement } from 'components/shared/AcademyAnalysis/types';
import NoOptionMenu from 'components/shared/NoOptionMenu';

interface Props {
  isFetching: boolean;
  sessionMeasurements: Measurement[];
}

const customStyles = {
  entrySelect: {
    minWidth: 250,
    maxWidth: 300,
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
    },
  },
  leaderboardIconStack: {
    alignItems: 'center',
  },
  leaderboardIcon: {
    fontSize: 32,
    color: 'black',
  },
  skeleton: {
    mr: 3,
    borderRadius: 2,
  },
};

const MetricSelectField = ({ isFetching, sessionMeasurements }: Props) => {
  const isInitial = useRef(true);

  const [dropDownValues, setDropDownValues] = useRecoilState(
    homeAcademyAnalysisDropDownValuesState
  );

  useEffect(() => {
    const isMeasurementsAvailable =
      isInitial.current &&
      sessionMeasurements &&
      sessionMeasurements.length === 0;

    if (!sessionMeasurements || isMeasurementsAvailable) {
      setDropDownValues((previous) => ({
        ...previous,
        measurementId: null,
      }));

      isInitial.current = false;

      return;
    }

    const defaultMeasurementsId = sessionMeasurements[0]?.id;

    if (defaultMeasurementsId) {
      setDropDownValues((previous) => ({
        ...previous,
        measurementId: defaultMeasurementsId,
      }));

      isInitial.current = true;
    }
  }, [sessionMeasurements, setDropDownValues]);

  return (
    <>
      {isFetching ? (
        <Skeleton
          variant="rectangular"
          width={250}
          height={35}
          sx={customStyles.skeleton}
        />
      ) : (
        <Stack direction="row" sx={customStyles.leaderboardIconStack}>
          <IconButton>
            <InfoIcon sx={customStyles.leaderboardIcon} />
          </IconButton>

          <Select
            size="small"
            placeholder="Nothing selected"
            value={dropDownValues.measurementId}
            onChange={async (event) => {
              setDropDownValues((previous) => ({
                ...previous,
                measurementId: Number(event.target.value),
              }));
            }}
            sx={customStyles.entrySelect}
          >
            {sessionMeasurements && sessionMeasurements.length > 0 ? (
              sessionMeasurements?.map((entry, index) => (
                <MenuItem key={index} value={entry.id}>
                  {entry.name} ({entry.stationName})
                </MenuItem>
              ))
            ) : (
              <NoOptionMenu />
            )}
          </Select>
        </Stack>
      )}
    </>
  );
};

export default MetricSelectField;
