import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslations } from 'use-intl';

import CommercialSectionSkelton from './CommercialSectionSkelton';
import DailySignUpsChart from './DailySignUpsChart';
import Link from 'components/MUI/Link';
import ContentContainer from 'components/shared/ContentContainer';
import PageContainer from 'components/shared/PageContainer';
import { trpc } from 'helpers/trpc';

const today = new Date();
const sevenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7));

const customStyles = {
  pageContainer: {
    mt: 0.5,
  },
  contentContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: 'black !important',
  },
  name: {
    pl: 4,
    color: 'black',
  },
  count: {
    pr: 10,
    color: 'black',
  },
  county: {
    pl: 10,
  },
  player: {
    pr: 10,
  },
  tableStack: {
    maxHeight: 470,
    overflow: 'hidden',
    ':hover': {
      overflowY: 'auto',
    },
  },
};

const CommercialSection = () => {
  const t = useTranslations('shared.common');

  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  const {
    data: counties,
    isFetching: isCountiesFetching,
    refetch: countiesRefetching,
  } = trpc.commercialArea.getPlayerCounties.useQuery(
    { dateFrom: sevenDaysAgo, dateTo: today },
    { enabled: false }
  );

  const {
    data: dailySignUps,
    isFetching: isDailySignUpsFetching,
    refetch: refetchDailySignUps,
  } = trpc.commercialArea.getSignUps.useQuery(
    { dateFrom: sevenDaysAgo, dateTo: today },
    { enabled: false }
  );

  useEffect(() => {
    refetchDailySignUps();
  }, [refetchDailySignUps, currentUser?.club.activeClubId]);

  useEffect(() => {
    countiesRefetching();
  }, [countiesRefetching, currentUser?.club.activeClubId]);

  const isDataFetching = isDailySignUpsFetching || isCountiesFetching;

  const dailySignUpsArea = (
    <ContentContainer
      containerTitle="Daily Sign-Ups"
      sx={customStyles.contentContainer}
      containerRight={
        <Link href="/commercialsignups">
          <IconButton>
            <KeyboardArrowRightIcon sx={customStyles.icon} />
          </IconButton>
        </Link>
      }
    >
      <DailySignUpsChart dailySignUps={dailySignUps?.signUps!} />
    </ContentContainer>
  );

  const CountiesRepresentedArea = (
    <ContentContainer
      containerTitle={`${t('counties')} represented`}
      sx={customStyles.contentContainer}
      containerRight={
        <Link href="/commercialmapsreports">
          <IconButton>
            <KeyboardArrowRightIcon sx={customStyles.icon} />
          </IconButton>
        </Link>
      }
    >
      <TableContainer sx={customStyles.tableStack}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={customStyles.county}>
                {t('county')}
              </TableCell>

              <TableCell align="right" sx={customStyles.player}>
                Players
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {counties?.playerCounties.length ? (
              counties.playerCounties.map((county, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    <Typography variant="h3" sx={customStyles.name} m={0}>
                      {county.name}
                    </Typography>
                  </TableCell>

                  <TableCell align="right" sx={customStyles.count}>
                    {county.playersCount}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  No players available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentContainer>
  );

  const commercialArea = (
    <Grid container spacing={6}>
      <Grid item xs={12} md={6}>
        {dailySignUpsArea}
      </Grid>

      <Grid item xs={12} md={6}>
        {CountiesRepresentedArea}
      </Grid>
    </Grid>
  );

  return (
    <PageContainer pageTitle="Commercial Area" sx={customStyles.pageContainer}>
      {isDataFetching ? <CommercialSectionSkelton /> : commercialArea}
    </PageContainer>
  );
};

export default CommercialSection;
