import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  defaultStringValue,
  firstElementIndex,
  firstRank,
  tableRowLimit,
} from '../pageHelpers';
import AttributeSelectField from './AttributeSelectField';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardSkeleton from './LeaderboardSkelton';
import LeaderboardRow from './LeaderboardTableRow';
import MetricSelectField from './MetricSelectField';
import {
  homeAcademyAnalysisDropDownOptionsState,
  homeAcademyAnalysisDropDownValuesState,
} from './pageAtoms';
import { getSession } from 'components/shared/AcademyAnalysis/pageHelpers';
import {
  Attribute,
  LeaderboardQueryParameters,
  MetricLeaderboardPlayer,
} from 'components/shared/AcademyAnalysis/types';
import ContentContainer from 'components/shared/ContentContainer';
import { trpc } from 'helpers/trpc';

interface Props {
  attributes: Attribute[];
}

const customStyles = {
  contentContainer: {
    height: '100%',
  },
  leaderboardTable: {
    pl: 4,
    pr: 3,
  },
};

const LeaderboardTable = ({ attributes }: Props) => {
  const dropDownValues = useRecoilValue(homeAcademyAnalysisDropDownValuesState);

  const dropDownOptions = useRecoilValue(
    homeAcademyAnalysisDropDownOptionsState
  );

  const session = useMemo(() => {
    if (dropDownOptions.sessions && dropDownValues.sessionId) {
      return getSession(dropDownOptions.sessions, dropDownValues.sessionId);
    }

    return null;
  }, [dropDownOptions?.sessions, dropDownValues.sessionId]);

  const leaderboardQueryParameters = useMemo(() => {
    const queryParameters: LeaderboardQueryParameters = {
      positionGroupIds: dropDownValues.positionGroupIds,
      positionIds: dropDownValues.positionIds,
    };

    if (session?.type === 'Lab session') {
      queryParameters.trialId = session.sessionId ?? undefined;
      queryParameters.eplAffiliationId = session.eplAffiliationId
        ? Number(session.eplAffiliationId)
        : undefined;
      queryParameters.entryMeasurementTypeId =
        dropDownValues.measurementId ?? undefined;
    }

    if (session?.type === 'Training session') {
      queryParameters.academyTeamId = dropDownValues.teamId ?? undefined;
      queryParameters.drillId = dropDownValues.measurementId ?? undefined;
    }

    return queryParameters;
  }, [
    dropDownValues.measurementId,
    dropDownValues.positionGroupIds,
    dropDownValues.positionIds,
    dropDownValues.teamId,
    session?.eplAffiliationId,
    session?.sessionId,
    session?.type,
  ]);

  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  const {
    data: leaderboard,
    refetch: refetchLeaderboard,
    isFetching: isLeaderboardFetching,
  } = trpc.academyAnalysis.getAcademyAnalysisSessionLeaderboard.useQuery(
    { ...leaderboardQueryParameters },
    { enabled: false }
  );

  const {
    data: labMeasurements,
    isFetching: isMeasurementsFetching,
    refetch: refetchMeasurements,
  } = trpc.shared.getLabSessionMeasurements.useQuery(
    { trialId: session?.sessionId! },
    { enabled: false }
  );

  const getRankedItems = (list: MetricLeaderboardPlayer[] | undefined) => {
    const sortedList =
      list?.sort((previous, next) => next.score - previous.score) ?? [];

    let rank = firstRank;
    let prevScore = sortedList[firstElementIndex]?.score;

    return sortedList.map((item, index) => {
      if (item.score < prevScore) {
        rank = index + firstRank;
        prevScore = item.score;
      }

      return { ...item, rank };
    });
  };

  const sessionMeasurements = labMeasurements?.sessionMeasurements;

  const getColumnTitle = () => {
    if (sessionMeasurements && sessionMeasurements.length === 0)
      return defaultStringValue;

    return (
      sessionMeasurements?.find(
        (measurements) => measurements.id === dropDownValues.measurementId
      )?.name ?? defaultStringValue
    );
  };

  useEffect(() => {
    refetchLeaderboard();
  }, [refetchLeaderboard, dropDownValues, currentUser?.club.activeClubId]);

  useEffect(() => {
    refetchMeasurements();
  }, [
    dropDownValues.teamId,
    dropDownValues.sessionId,
    dropDownValues.positionIds,
    dropDownValues.positionGroupIds,
    currentUser?.club.activeClubId,
    refetchMeasurements,
  ]);

  const leaderboardTable = (
    <TableContainer>
      <Table>
        <LeaderboardHeader selectedEntry={getColumnTitle()} />

        {isLeaderboardFetching ? (
          <LeaderboardSkeleton />
        ) : (
          <TableBody>
            {leaderboard?.leaderboard.length! > 0 ? (
              getRankedItems(
                leaderboard?.leaderboard.slice(0, tableRowLimit)
              ).map((player, index) => (
                <LeaderboardRow key={index} player={player} />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  No players available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );

  return (
    <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
      <ContentContainer
        containerTitle="Leaderboard"
        containerRight={
          session?.type === 'Lab session' ? (
            <MetricSelectField
              isFetching={isMeasurementsFetching}
              sessionMeasurements={sessionMeasurements ?? []}
            />
          ) : (
            <AttributeSelectField
              isFetching={isMeasurementsFetching}
              attributes={attributes}
            />
          )
        }
        sx={customStyles.contentContainer}
      >
        <Box sx={customStyles.leaderboardTable}>{leaderboardTable}</Box>
      </ContentContainer>
    </Grid>
  );
};

export default LeaderboardTable;
