import { atom } from 'recoil';

import {
  defaultDropDownOptions,
  defaultDropDownValues,
} from 'components/shared/AcademyAnalysis/pageHelpers';

export const homeAcademyAnalysisDropDownValuesState = atom({
  key: 'homeAcademyAnalysisDropDownValuesState',
  default: defaultDropDownValues,
});

export const homeAcademyAnalysisDropDownOptionsState = atom({
  key: 'homeAcademyAnalysisDropDownOptionsState',
  default: defaultDropDownOptions,
});
