import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import { convertSignUpsToRechartFormat } from 'components/pages/CommercialSignUps/pageHelpers';
import CustomBarChart from 'components/shared/Charts/CustomBarChart';

interface DailySignUp {
  date: string;
  playersCount: number;
}

interface Props {
  dailySignUps: DailySignUp[];
}

const DailySignUpsChart = ({ dailySignUps }: Props) => {
  const signUpsData = useMemo(() => {
    if (dailySignUps) {
      const convertedSignUps = convertSignUpsToRechartFormat(dailySignUps);

      return convertedSignUps.map((signUp) => ({
        xAxis: signUp.date,
        value: signUp.playersCount,
      }));
    }

    return [];
  }, [dailySignUps]);

  return (
    <Box mt={11} ml={-6}>
      <CustomBarChart
        barHeight={500}
        data={signUpsData}
        emptyMessage="No sign-ups were found"
        tooltipLabel="sign-ups"
      />
    </Box>
  );
};

export default DailySignUpsChart;
