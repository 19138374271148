import { Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { defaultStringValue } from '../pageHelpers';
import LeaderboardTable from './LeaderboardTable';
import {
  homeAcademyAnalysisDropDownOptionsState,
  homeAcademyAnalysisDropDownValuesState,
} from './pageAtoms';
import CardSection from 'components/shared/AcademyAnalysis/CardSection';
import commonStyles from 'components/shared/AcademyAnalysis/commonStyles';
import DataLog from 'components/shared/AcademyAnalysis/DataLog';
import DropDownSection from 'components/shared/AcademyAnalysis/DropDownSection';
import { getSession } from 'components/shared/AcademyAnalysis/pageHelpers';
import { AnalysisQueryParameters } from 'components/shared/AcademyAnalysis/types';
import PageContainer from 'components/shared/PageContainer';
import { castActiveState } from 'state/atoms';
import { trpc } from 'helpers/trpc';

const customStyles = {
  pageContainer: {
    mt: 0.5,
  },
};

const AcademyAnalysisSection = () => {
  const castActive = useRecoilValue(castActiveState);

  const [dropDownOptions, setDropDownOptions] = useRecoilState(
    homeAcademyAnalysisDropDownOptionsState
  );
  const [dropDownValues, setDropDownValues] = useRecoilState(
    homeAcademyAnalysisDropDownValuesState
  );

  const academyAnalysisQueryProps = useMemo(() => {
    if (castActive) {
      return {
        refetchInterval: 1000 * 1,
        refetchIntervalInBackground: true,
      };
    }

    return { enabled: false };
  }, [castActive]);

  const session = useMemo(() => {
    if (dropDownOptions.sessions && dropDownValues.sessionId) {
      return getSession(dropDownOptions.sessions, dropDownValues.sessionId);
    }

    return null;
  }, [dropDownOptions?.sessions, dropDownValues.sessionId]);

  const analysisQueryParameters = useMemo(() => {
    const queryParameters: AnalysisQueryParameters = {
      trialId: session?.sessionId!,
      positionGroupIds: dropDownValues.positionGroupIds,
      positionIds: dropDownValues.positionIds,
    };

    if (session?.type === 'Lab session') {
      queryParameters.eplAffiliationId = session.eplAffiliationId
        ? Number(session.eplAffiliationId)
        : undefined;
    }

    if (session?.type === 'Training session') {
      queryParameters.academyTeamId = dropDownValues.teamId ?? undefined;
    }

    return queryParameters;
  }, [
    dropDownValues.positionGroupIds,
    dropDownValues.positionIds,
    dropDownValues.teamId,
    session?.eplAffiliationId,
    session?.sessionId,
    session?.type,
  ]);

  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  const {
    data: academyAnalysis,
    refetch: refetchDashboard,
    isFetching: isDashboardFetching,
  } = trpc.academyAnalysis.getAcademyAnalysisSessionDashboard.useQuery(
    { ...analysisQueryParameters },
    academyAnalysisQueryProps
  );

  useEffect(() => {
    refetchDashboard();
  }, [
    refetchDashboard,
    dropDownValues.teamId,
    dropDownValues.sessionId,
    dropDownValues.positionIds,
    dropDownValues.positionGroupIds,
    currentUser?.club.activeClubId,
  ]);

  return (
    <PageContainer
      pageTitle="Academy analysis"
      sx={customStyles.pageContainer}
      headerStyles={commonStyles.headerStyles}
      headerRight={
        <DropDownSection
          dropDownValues={dropDownValues}
          setDropDownValues={setDropDownValues}
          setDropDownOptions={setDropDownOptions}
        />
      }
    >
      <Grid container spacing={8}>
        <CardSection
          academyAnalysis={academyAnalysis!}
          sessionName={session?.name ?? defaultStringValue}
          isLoading={isDashboardFetching}
        />

        <LeaderboardTable attributes={academyAnalysis?.attributeScores ?? []} />

        <DataLog
          isHome={true}
          isDataFetching={isDashboardFetching}
          academyAnalysis={academyAnalysis}
        />
      </Grid>
    </PageContainer>
  );
};

export default AcademyAnalysisSection;
