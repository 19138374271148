import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Step,
  StepContent,
  Table,
  TableContainer,
} from '@mui/material';

import { playersPerPage } from '../pageHelpers';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardHeaderSkeleton from './LeaderboardHeaderSkeleton';
import LeaderboardSkeleton from './LeaderboardSkeleton';
import Link from 'components/MUI/Link';
import ContentContainer from 'components/shared/ContentContainer';
import CustomStepper from 'components/shared/Stepper/CustomStepper';

const customStyles = {
  avatar: {
    position: 'relative',
    right: 8,
    top: 2,
    zIndex: 1,
    height: 48,
    width: 48,
  },
  contentContainer: {
    height: '100%',
  },
  icon: {
    color: 'black !important',
  },
  name: {
    width: '10vW',
    mt: -12,
  },
  skill: {
    width: '4vW',
  },
  mainBox: {
    mt: 3,
    ml: -3,
    pl: 3.5,
  },
  headingSkeleton: {
    height: 40,
    width: 200,
  },
  rightIcon: {
    width: 30,
    height: 30,
  },
  stepContent: {
    pb: 6,
    pl: 10,
    ml: 4,
  },
};

interface Props {
  accessAvailable: boolean;
}

const watchListSkeleton = (
  <Box sx={customStyles.mainBox}>
    <CustomStepper>
      {Array.from({ length: playersPerPage }).map((_, index) => (
        <Step key={index} expanded={true}>
          <Skeleton sx={customStyles.avatar} variant="circular" />

          <StepContent
            sx={{
              ...customStyles.stepContent,
              borderLeft:
                index < playersPerPage - 1 ? '1px dashed #DCDCDC' : 'none',
            }}
          >
            <Skeleton sx={customStyles.name} variant="text" />

            <Skeleton sx={customStyles.skill} variant="text" />
          </StepContent>
        </Step>
      ))}
    </CustomStepper>
  </Box>
);

const RecruitmentCenterSkeleton = ({ accessAvailable }: Props) => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={8}>
      <ContentContainer
        containerTitle={
          accessAvailable ? (
            'Leaderboard'
          ) : (
            <Skeleton sx={customStyles.headingSkeleton} />
          )
        }
        containerRight={
          accessAvailable ? (
            <Link href="/leaderboards">
              <IconButton>
                <KeyboardArrowRightIcon sx={customStyles.icon} />
              </IconButton>
            </Link>
          ) : (
            <Skeleton variant="circular" sx={customStyles.rightIcon} />
          )
        }
        sx={customStyles.contentContainer}
      >
        <TableContainer>
          <Table>
            {accessAvailable ? (
              <LeaderboardHeader />
            ) : (
              <LeaderboardHeaderSkeleton />
            )}

            <LeaderboardSkeleton />
          </Table>
        </TableContainer>
      </ContentContainer>
    </Grid>

    <Grid item xs={12} md={4}>
      <ContentContainer
        containerTitle={
          accessAvailable ? (
            'Watchlist'
          ) : (
            <Skeleton sx={customStyles.headingSkeleton} />
          )
        }
        containerRight={
          accessAvailable ? (
            <Link href="/playermanagement">
              <IconButton>
                <KeyboardArrowRightIcon sx={customStyles.icon} />
              </IconButton>
            </Link>
          ) : (
            <Skeleton variant="circular" sx={customStyles.rightIcon} />
          )
        }
        sx={customStyles.contentContainer}
      >
        {watchListSkeleton}
      </ContentContainer>
    </Grid>
  </Grid>
);

export default RecruitmentCenterSkeleton;
