import type { GetStaticPropsContext, NextPage } from 'next';

import HomeContent from 'components/pages/HomeContent';
import ContainerWrapper from 'components/shared/ContainerWrapper';
import Header from 'components/shared/Header';
import Sidebar from 'components/shared/Sidebar';
import UserSessionProvider from 'components/shared/UserSessionProvider';
import { getMessagesForLocale } from 'helpers/getMessagesForLocale';

const Home: NextPage = () => (
  <UserSessionProvider allowedRoles={['All']}>
    <Header />
    <ContainerWrapper>
      <Sidebar />
      <HomeContent />
    </ContainerWrapper>
  </UserSessionProvider>
);

export default Home;

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      ...(await getMessagesForLocale({
        locale,
        fileNames: ['shared'],
      })),
    },
  };
}
