import {
  Box,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { playersPerPage } from '../pageHelpers';

const customStyles = {
  boxSkeleton: {
    height: 35,
  },
  avatarSkeleton: {
    width: 48,
    height: 48,
  },
  nameSkelton: {
    width: '10vw',
    height: 35,
    maxWidth: 'calc(100% - 10px)',
  },
  teamSkelton: {
    width: '6vw',
    height: 25,
    maxWidth: 'calc(100% - 10px)',
  },
  avatarCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  player: {
    pl: 2,
  },
  country: {
    width: 100,
    p: 0,
    pl: 2,
  },
};

const LeaderboardSkeleton = () => (
  <TableBody>
    {Array.from({ length: playersPerPage }).map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton sx={customStyles.boxSkeleton} variant="text" />
        </TableCell>

        <TableCell align="center">
          <Box sx={customStyles.avatarCell}>
            <Skeleton sx={customStyles.avatarSkeleton} variant="circular" />
          </Box>
        </TableCell>

        <TableCell align="left" sx={customStyles.player}>
          <Stack>
            <Skeleton sx={customStyles.nameSkelton} variant="text" />

            <Skeleton sx={customStyles.teamSkelton} variant="text" />
          </Stack>
        </TableCell>

        <TableCell align="center" sx={customStyles.country}>
          <Skeleton variant="rounded" width={75} height={50} />
        </TableCell>

        {Array.from({ length: 2 }).map((_, index) => (
          <TableCell key={index} align="center">
            <Skeleton sx={customStyles.boxSkeleton} variant="text" />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
);

export default LeaderboardSkeleton;
