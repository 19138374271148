import { TableCell, TableHead, TableRow } from '@mui/material';

const customStyles = {
  player: {
    pl: 0,
  },
};

const LeaderboardHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell align="center">Rank</TableCell>
      <TableCell align="center">Photo</TableCell>
      <TableCell align="left" sx={customStyles.player}>
        Player
      </TableCell>
      <TableCell align="center">Country</TableCell>
      <TableCell align="center">Age</TableCell>
      <TableCell align="center">NRS SCORE</TableCell>
    </TableRow>
  </TableHead>
);

export default LeaderboardHeader;
