import React from 'react';

import Link from 'components/MUI/Link';

interface Props {
  playerId: number | string;
  children: React.ReactNode;
}

const customStyles = {
  link: {
    textDecoration: 'none',
    color: 'black',
  },
};

const PlayerProfileLink = ({ playerId, children }: Props) => (
  <Link
    href={`playerprofile?playerId=${playerId}`}
    target="_blank"
    rel="noreferrer"
    sx={customStyles.link}
  >
    {children}
  </Link>
);

export default PlayerProfileLink;
