import generateRandomColor from 'helpers/generateRandomColor';
import getRegionalDate from 'helpers/getRegionalDate';
import { SignUp } from 'server/routers/commercialArea/types';

export const barHeight = 500;

export const convertSignUpsToRechartFormat = (signUps: SignUp[]) => {
  return signUps?.map((signUp) => ({
    ...signUp,
    date: getRegionalDate(signUp.date),
    fill: generateRandomColor(),
  }));
};
