import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslations } from 'next-intl';

import { playersPerPage } from '../pageHelpers';
import Link from 'components/MUI/Link';
import ContentContainer from 'components/shared/ContentContainer';

const customStyles = {
  countySkeleton: {
    pl: 10,
    ml: 4,
    height: 20,
    width: '50%',
  },
  playerSkeleton: {
    pr: 10,
    mr: 4,
    height: 20,
    width: 40,
  },
  avatarSkeleton: {
    width: 48,
    height: 48,
  },
  nameSkelton: {
    width: '10vw',
    height: 15,
    maxWidth: 'calc(100% - 10px)',
  },
  teamSkelton: {
    width: '6vw',
    height: 15,
    maxWidth: 'calc(100% - 10px)',
  },
  avatarCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainer: {
    height: '100%',
  },
  signUpsSkeleton: {
    my: 5,
    height: 300,
    borderRadius: 4,
  },
  player: {
    display: 'flex',
    justifyContent: 'end',
  },
  icon: {
    color: 'black !important',
  },
  county: {
    pl: 10,
  },
  playerHead: {
    pr: 10,
  },
};

const CommercialSectionSkelton = () => {
  const t = useTranslations('shared.common');

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={6}>
        <ContentContainer
          containerTitle="Daily Sign-Ups"
          containerRight={
            <Link href="/commercialsignups">
              <IconButton>
                <KeyboardArrowRightIcon sx={customStyles.icon} />
              </IconButton>
            </Link>
          }
          sx={customStyles.contentContainer}
        >
          <Skeleton sx={customStyles.signUpsSkeleton} variant="rectangular" />
        </ContentContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <ContentContainer
          containerTitle={`${t('counties')} represented`}
          containerRight={
            <Link href="/commercialmapsreports">
              <IconButton>
                <KeyboardArrowRightIcon sx={customStyles.icon} />
              </IconButton>
            </Link>
          }
          sx={customStyles.contentContainer}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={customStyles.county}>
                    {t('county')}
                  </TableCell>

                  <TableCell align="right" sx={customStyles.playerHead}>
                    Players
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Array.from({ length: playersPerPage }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <Skeleton
                        sx={customStyles.countySkeleton}
                        variant="text"
                      />
                    </TableCell>

                    <TableCell align="right">
                      <Box sx={customStyles.player}>
                        <Skeleton
                          sx={customStyles.playerSkeleton}
                          variant="text"
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ContentContainer>
      </Grid>
    </Grid>
  );
};

export default CommercialSectionSkelton;
