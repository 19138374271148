import { Typography } from '@mui/material';
import { animate } from 'framer-motion';
import { useEffect, useRef } from 'react';

interface Props {
  endValue: number;
}

const customStyles = {
  count: {
    fontSize: 64,
    color: 'black',
  },
};

const Counter = ({ endValue }: Props) => {
  const count = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const controls = animate(0, endValue, {
      duration: 2,
      onUpdate(value) {
        if (count.current) {
          const roundedValue = Math.round(value);
          count.current.textContent = roundedValue.toString();
        }
      },
    });

    return () => controls.stop();
  }, [endValue]);

  return <Typography sx={customStyles.count} ref={count} />;
};

export default Counter;
