import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

interface Props {
  selectedEntry: string;
}

const LeaderboardHeader = ({ selectedEntry }: Props) => (
  <TableHead>
    <TableRow>
      <TableCell align="center">Rank</TableCell>
      <TableCell align="center">Photo</TableCell>
      <TableCell align="left">Player</TableCell>
      <TableCell align="left">Position</TableCell>
      <TableCell align="center">{selectedEntry}</TableCell>
    </TableRow>
  </TableHead>
);

export default LeaderboardHeader;
